<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>


  <table class="table table-striped" v-bind:id="component_id">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <template v-for="field_meta in field_metas" v-bind:key="field_meta.label">
          <th>{{ field_meta.label }}</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="(row) in component_value" v-bind:key="row.id">
        <ml-field-row v-bind:value="row" v-bind:row="field_metas" v-bind:id="row.id"
          @row_update_value="row_update_value"></ml-field-row>
      </template>

    </tbody>
  </table>
</template>

<script>
import Row from "./Row.vue"
export default {
  components: {
    'ml-field-row': Row
  },
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let items = {};

    // 載入組件清單給下拉元件
    if (this.field.component.properties.source_json != '' && this.field.component.properties.source_json != undefined) {
      items = JSON.parse(this.field.component.properties.source_json);
      for (let key in items) {
        items[key] = { 'title': items[key] };
      }
    }
    else {

      let parameters = '';
      let params = {};

      if (this.field.component.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < this.field.component.properties.sort_rule_fields.length; i++) {
          let variable = this.field.component.properties.sort_rule_fields[i].field_variable;
          let rule = this.field.component.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      if (this.field.component.properties.datazone != '' && this.field.component.properties.datazone != undefined) {
        params['dz'] = this.field.component.properties.datazone;
      }

      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }


      let api_url = this.$microlink.base_api_url + this.field.component.properties.source_api + parameters;

      // 如果有設定快取，則從快取中取得資料
      if (this.field.component.properties.pool == 'Y') {

        let pool_key = this.$microlink.base64_encode(api_url);
        if (this.$microlink.get_data_pool(pool_key) == undefined) {
          items = this.$microlink.api(api_url);
          items = items.data;
          this.$microlink.set_data_pool(pool_key, items);
        }
        else {
          items = this.$microlink.get_data_pool(pool_key);
        }
      }
      else {
        items = this.$microlink.api(api_url);
        items = items.data;
      }
    }

    let localValue = this.value;

    let id_key = this.field.component.properties.mapping_to_table_field;
    if (id_key == undefined || id_key == '') {
      id_key = 'id';
    }

    if (localValue == undefined) {
      localValue = [];
    }
    else {
      // 從後端讀到如果有資料的話，就將資料的識別碼做為 key，以利後續的處理
      let localValue_length = localValue.length;
      for (let i = 0; i < localValue_length; i++) {
        let id = localValue[i][id_key];
        localValue[id] = localValue[i];
        delete localValue[i];
      }
    }

    // 合併 localValue 與 items 的屬性
    for (let key in items) {
      if (localValue[key] != undefined) {
        items[key] = Object.assign(items[key], localValue[key]);
      }
      else {
        let item = {};
        item[id_key] = items[key].id;
        for (let key in this.field.component.properties.fields) {
          item[this.field.component.properties.fields[key].variable] = '';
        }
        items[key] = Object.assign(items[key], item);
      }
    }

    let fields = this.field.component.properties.fields;

    return {
      component_id: 'matrix-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: items,
      field_metas: fields
    }
  },
  mounted() {
    for (let key in this.field_metas) {
      this.field_metas[key]._component = this.$microlink.components[this.field_metas[key].component.type].component;
    }
    let $event = {};
    $event.temp = this.component_value;
    this.$emit('input', $event);
  },
  methods: {
    row_update_value: function ($event) {
      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

th.operate {
  width: 60px;
}
</style>